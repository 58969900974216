import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';

import Head from 'components/head';

import Section from '~v2/section';
import Content from '~v2/blocks/content';
import Media from '~v2/blocks/media';
import Copy from '~v2/elements/copy';

import ContactForm from '~v2/elements/forms/netlify/contact-form';

import Box from '~v2/elements/container/box';
import { colors } from 'constants/theme';

const Contact = ({
  data: {
    contactJson: { title, hero_section, help_section },
  },
}) => (
  <Layout isSmallHeader={true}>
    <Head
      pageTitle={title}
      pageDescription="Our cutting edge software provides security and vital marketing differentiation: contact us today to learn how we can help you grow your business."
    />
    <Section
      boundsProps={{
        fluid: true,
        style: { width: '100%', overflow: 'hidden' },
        valign: 'center',
        TABLET_DOWN: { direction: 'vertical', margin: '4rem 0 0' },
        TABLET_UP: {
          direction: 'horizontal',
          margin: '5rem 0 0',
        },
        DESKTOP_UP: {
          margin: '6rem 0 0',
          minHeight: '70vh',
        },
      }}
      containerProps={{
        bgColor: colors.white,
        fontColor: colors.cool_grey,
      }}
      left={{
        children: (
          <Media
            style={{ position: 'relative' }}
            fluid={hero_section.image.childImageSharp.fluid}
          />
        ),
        boxProps: {
          fluid: true,
          shiftDown: true,
          className: 'tablet-order-plus-one',
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '115%', margin: '0 -25% 0 -15%', shiftDown: true },
          DESKTOP_UP: { width: '50%' },
        },
      }}
      right={{
        children: (
          <Box containerWidth="450px">
            <Content
              header={{
                as: 'h1',
                variant: 'subheading',
                text: hero_section.title,
              }}
            >
              <Copy size="lg">{hero_section.copy}</Copy>
              <ContactForm />
            </Content>
          </Box>
        ),
        boxProps: {
          fluid: true,
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '100%' },
          DESKTOP_UP: { width: '50%' },
        },
      }}
    />

    <Section
      boundsProps={{
        fluid: true,
        style: { minHeight: '50vh', width: '100%', 'align-items': 'stretch' },
      }}
      containerProps={{
        bgColor: colors.collective_red,
        fontColor: colors.warm_grey,
      }}
      left={{
        children: (
          <div
            style={{
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              height: '100%',
            }}
          >
            <Box
              style={{
                marginLeft: 'auto',
              }}
            >
              <Content
                header={{
                  as: 'h2',
                  fontSize: '4rem',
                  text: help_section.title,
                }}
                button={{
                  style: 'solid',
                  color: colors.warm_grey,
                  text: help_section.button.text,
                  to: help_section.button.to,
                  externalLink: true,
                }}
              >
                <Copy size="lg">
                  call our help line,
                  <br />
                  9:00 am - 5:00 pm.
                  <br />
                  Eastern Standard time.
                </Copy>
              </Content>
            </Box>
          </div>
        ),
        boxProps: {
          fluid: true,
          TABLET_DOWN: { width: '75%', shiftRight: true },
          TABLET: { width: 'auto', shiftRight: true },
          DESKTOP_UP: { width: '50%', shiftRight: true },
        },
      }}
      right={{
        boxProps: {
          fluid: true,
          fill: true,
          TABLET_DOWN: { width: '25%' },
          TABLET: { width: '35%' },
          DESKTOP_UP: { width: '50%' },
        },
        backgroundProps: {
          image: help_section.image,
        },
      }}
    />
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      hero_section {
        title
        copy
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      help_section {
        title
        button {
          text
          to
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
