import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

import { colors } from 'constants/theme';

export const FormContainer = styled.div`
  position: relative;
  ${'' /* overflow: hidden; */}
  padding: 0.2rem 0;

  form {
    display: block;
    width: 100%;
  }
`;

// export const SubmitBtn = styled.button`
//   background: #75b589;
//   border: 1px solid transparent;
//   border-radius: 1px;
//   padding: 1rem;
//   font-family: Lora;
//   font-size: 12px;
//   color: #fff;
//   letter-spacing: 1.8px;
//   text-align: center;
//   text-transform: uppercase;
//   transition: background 0.1s linear;

//   &:hover {
//     background: #64ac7b;
//   }
// `;

export const ErrorMsg = styled.span`
  font-size: 1.2rem;
  margin-left: 2rem;
`;

export const LoadingIcon = styled.div`
  position: absolute !important;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  opacity: 0.4;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;

  #trace-logo {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

export const FieldGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;

  ${MEDIA.PHONE`
    align-items: unset;
    flex-flow: column;
    margin-bottom: 0;
  `};

  ${MEDIA.TABLET`
    align-items: unset;
    flex-flow: column;
    margin-bottom: 0;
  `};

  .input-field {
    flex: 1 1 auto;
    box-sizing: border-box;

    & > input,
    & > textarea {
      box-sizing: border-box;
      background-color: ${colors.cool_grey};
      color: ${colors.white};
      font-size: 1rem;
      font-family: 'Montserrat';
      border: none;
      padding: 0.8rem;
      width: 100%;
    }

    ${MEDIA.PHONE`
      margin-bottom: 0.8rem;
    `};

    ${MEDIA.TABLET`
      margin-bottom: 0.8rem;
    `};

    & + .input-field {
      margin-left: 0.8rem;

      ${MEDIA.PHONE`
        margin-left: 0rem;
      `};

      ${MEDIA.TABLET`
        margin-left: 0rem;
      `};
    }

    & > textarea {
      min-height: 120px;
    }
  }
`;

export const SuccessMsg = styled.div`
  position: relative;
  padding: 2rem 5rem;
  z-index: 105;
  text-align: center;

  ${MEDIA.PHONE`
    padding: 2rem;
  `};

  h3 {
    color: ${colors.dusty_gold};
  }
`;
