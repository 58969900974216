/**
 * Encode Object of Form Data into String for Netlify.
 *
 * @param {*} data
 */
export const encodeFormData = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};
