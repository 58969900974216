import React from 'react';
import { PoseGroup } from 'react-pose';
import ReactGA from 'react-ga';
import { useFormState } from 'react-use-form-state';
import MEDIA from 'helpers/mediaTemplates';
import styled from 'styled-components';

import { FadeIn, FlyInFromRight, FlyInFromBottom } from 'constants/pose';
import Title from '~v2/elements/title';
import LogoSVG from 'images/svg/trace-logo/icon.svg';
import Button from '~v2/elements/button';
import Copy from '~v2/elements/copy';

import {
  FieldGroup,
  FormContainer,
  LoadingIcon,
  SuccessMsg,
} from '../forms.css';

import { encodeFormData } from '../utils';

// eslint-disable-next-line no-unused-vars, react/prop-types
export default function ContactForm() {
  const [formState, { text, email, textarea }] = useFormState({
    loading: false,
    result: '',
  });

  const { loading, result } = formState.values;

  const handleSubmit = event => {
    formState.setField('loading', true);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeFormData({ 'form-name': 'contact', ...formState.values }),
    })
      .then(() => {
        fetch('/.netlify/functions/form-alert', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'form-name': 'contact', ...formState.values }),
        }).then(() => {
          formState.setField('loading', false);
          formState.setField('result', 'success');

          ReactGA.event({
            category: 'Form Submission',
            action: 'User filled out netlify contact us form',
          });
        });
      })
      .catch(error => alert(error));

    event.preventDefault();
  };

  return (
    <FormContainer className="lead-capture-form" result={result}>
      <PoseGroup>
        {'success' !== result && (
          <FlyInFromRight key="form">
            <form
              name="contact"
              onSubmit={handleSubmit}
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <FieldGroup>
                <div className="input-field">
                  <input {...text('name')} required placeholder="Name*" />
                </div>
                <div className="input-field">
                  <input {...email('email')} required placeholder="Email*" />
                </div>
              </FieldGroup>
              <FieldGroup>
                <div className="input-field">
                  <input {...text('subject')} required placeholder="Subject*" />
                </div>
              </FieldGroup>
              <FieldGroup>
                <div className="input-field">
                  <textarea
                    {...textarea('message')}
                    required
                    placeholder="Message*"
                  />
                </div>
              </FieldGroup>
              <FieldGroup>
                <Button buttonRole="submit" size="sm">
                  Submit
                </Button>

                <EmailLinkContainer>
                  <Copy size="sm" marginBottom="0">
                    Or if you prefer to contact us via email{' '}
                    <a href="mailto:info@tracevt.com">info@tracevt.com</a>
                  </Copy>
                </EmailLinkContainer>
              </FieldGroup>
            </form>
          </FlyInFromRight>
        )}

        {loading && (
          <FadeIn key="loading">
            <LoadingIcon>
              <LogoSVG type="icon" />
            </LoadingIcon>
          </FadeIn>
        )}

        {'success' === result && (
          <FlyInFromBottom key="success">
            <SuccessMsg>
              <Title as="h3" variant="subheading">
                Thank you for your interest in Trace!
              </Title>
            </SuccessMsg>
          </FlyInFromBottom>
        )}
      </PoseGroup>
    </FormContainer>
  );
}

const EmailLinkContainer = styled.div`
  margin-left: 2rem;

  ${MEDIA.PHONE`
    margin-top: 1rem;
    margin-left: 0;
    margin-bottom: -1rem;
  `};
`;
